import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import memoize from "utils/memoize";

export const RHDarkCalendarIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 12 12" fill="none" {...props}>
      <rect
        x="0.25"
        y="1.58301"
        width="10.8333"
        height="10.1667"
        rx="0.25"
        stroke="black"
        stroke-width="0.5"
        stroke-linejoin="round"
      />
      <rect y="1.33301" width="11.3333" height="2.66667" fill="black" />
      <rect
        x="1.33203"
        width="0.666667"
        height="3.33333"
        rx="0.333333"
        fill="black"
      />
      <rect
        x="9.33203"
        width="0.666667"
        height="3.33333"
        rx="0.333333"
        fill="black"
      />
    </SvgIcon>
  );
};

export default memoize(RHDarkCalendarIcon);
