import "react-international-phone/build/index.css";

import {
  BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import {
  CountryIso2,
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput
} from "react-international-phone";
import RHDropdownIcon from "icons/RHDropdownIcon";
import memoize from "utils/memoize";

export interface RHPhoneInputProps extends BaseTextFieldProps {
  value: string;
  defaultCountry: string;
  onChange: (phone: string, countryCode: CountryIso2) => void;
  errorMessages: string | any[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phoneNumberInput: {
      "& .MuiInputBase-root": {
        backgroundColor: "#f9f7f4"
      },
      height: "auto",
      width: "100%",
      minHeight: 48
    },
    phoneNumberSelect: {
      paddingBottom: 0,
      width: "80px",
      // Remove default outline (display only on focus)
      fieldset: {
        display: "none"
      },
      '&.Mui-focused:has(div[aria-expanded="false"])': {
        fieldset: {
          display: "block"
        }
      },
      // Update default spacing
      "& > .MuiSelect-select": {
        width: "50px",
        padding: "10px 24px 10px 14px !important",
        backgroundColor: "#f9f7f4",
        borderRight: "1px solid rgb(233, 233, 233)"
      }
    },
    errorText: {
      color: "#CA6667",
      marginLeft: 14,
      margiRight: 14,
      marginBottom: 0,
      marginTop: 3,
      textAlign: "left",
      fontSize: 12,
      fontWeight: 400
    }
  })
);

export const RHPhoneInput: React.FC<RHPhoneInputProps> = ({
  value,
  defaultCountry,
  onChange,
  ...restProps
}) => {
  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: defaultCountry,
      value,
      disableDialCodePrefill: true,
      disableDialCodeAndPrefix: true,
      countries: defaultCountries,
      onChange: data => {
        onChange(data.phone, data.country);
      }
    });

  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (phone.length > 0) {
      setIsEmpty(false);
    }
  }, [phone]);

  const classes = useStyles();

  return (
    <>
      <TextField
        name={"rhPhoneInput"}
        variant="outlined"
        color="primary"
        placeholder="Phone Number*"
        value={phone}
        onChange={handlePhoneValueChange}
        onBlur={() => {
          if (phone.length === 0) {
            setIsEmpty(true);
          }
        }}
        type="tel"
        inputRef={inputRef}
        className={classes.phoneNumberInput}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              style={{ marginRight: "2px", marginLeft: "-8px" }}
            >
              <Select
                MenuProps={{
                  style: {
                    height: "300px",
                    width: "360px",
                    top: "10px",
                    left: "-34px"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  }
                }}
                disableUnderline
                IconComponent={RHDropdownIcon}
                className={classes.phoneNumberSelect}
                value={country}
                onChange={e => setCountry(e.target.value as CountryIso2)}
                renderValue={(value: CountryIso2) => (
                  <FlagEmoji iso2={value} style={{ display: "flex" }} />
                )}
              >
                {defaultCountries.map(c => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagEmoji
                        iso2={country.iso2}
                        style={{ marginRight: "8px" }}
                      />
                      <Typography style={{ marginRight: "8px" }}>
                        {country.name}
                      </Typography>
                      <Typography>+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          )
        }}
        {...restProps}
      />
      {isEmpty && (
        <p className={classes.errorText}>{restProps.errorMessages[0]}</p>
      )}
    </>
  );
};

export default memoize(RHPhoneInput);
