import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import memoize from "utils/memoize";

export const RHTimeIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 14 14" {...props}>
      <path
        d="M0.925 7C0.925 10.3209 3.60909 13.0298 6.925 13.0701V13.075H7C10.3504 13.075 13.075 10.3504 13.075 7C13.075 3.64962 10.3504 0.925 7 0.925C3.64959 0.925 0.925 3.65414 0.925 7ZM1.41082 7C1.41082 3.91605 3.91605 1.41082 7 1.41082C10.0795 1.41082 12.5892 3.91609 12.5892 7C12.5892 10.084 10.084 12.5892 7 12.5892C3.91605 12.5892 1.41082 10.084 1.41082 7Z"
        fill="black"
        stroke="black"
        stroke-width="0.15"
      />
      <path
        d="M6.75312 7.89107C6.75312 8.00036 6.82454 8.09289 6.92327 8.12455V8.1407H6.99827C7.13373 8.1407 7.24342 8.031 7.24342 7.89555V3.66868C7.24342 3.53323 7.13373 3.42354 6.99827 3.42354C6.86282 3.42354 6.75312 3.53323 6.75312 3.66868V7.89107Z"
        fill="black"
        stroke="black"
        stroke-width="0.15"
      />
      <path
        d="M11.2129 8.13164C11.3221 8.13164 11.4147 8.06023 11.4463 7.96149H11.4625V7.88649C11.4625 7.75104 11.3528 7.64134 11.2173 7.64134H6.99046C6.85501 7.64134 6.74531 7.75104 6.74531 7.88649C6.74531 8.02194 6.85501 8.13164 6.99046 8.13164H11.2129Z"
        fill="black"
        stroke="black"
        stroke-width="0.15"
      />
    </SvgIcon>
  );
};

export default memoize(RHTimeIcon);
