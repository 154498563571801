import React from "react";
import { InputAdornment, TextField, Theme, useMediaQuery } from "@mui/material";
import RHDropdownIcon from "icons/RHDropdownIcon";
import RHDarkCalendarIcon from "icons/RHDarkCalendarIcon";
import memoize from "utils/memoize";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const RHCustomDatePicker = props => {
  const {
    children,
    error,
    fullWidth,
    helperText,
    id,
    inputProps,
    label,
    onBlur,
    onChange,
    value,
    style,
    className,
    iconColor
  } = props;

  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  const Comp = smDown ? MobileDatePicker : DatePicker;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Comp
        views={["day"]}
        components={{
          OpenPickerIcon: props => {
            return (
              <RHDarkCalendarIcon
                {...props}
                style={{ width: "12px", height: "12px", fill: "none" }}
              />
            );
          }
        }}
        disablePast={true}
        inputFormat={"MMM DD, YYYY"}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            helperText={helperText}
            fullWidth={fullWidth}
            error={error}
            style={style ? style : undefined}
            className={className ? className : undefined}
            InputProps={
              smDown
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <RHDarkCalendarIcon
                          style={{
                            width: "12px",
                            height: "12px",
                            fill: "none"
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <RHDropdownIcon
                        color={iconColor ? iconColor : undefined}
                      />
                    )
                  }
                : params.InputProps
            }
          />
        )}
        onChange={onChange}
        value={value}
        closeOnSelect
      />
    </LocalizationProvider>
  );
};

export default memoize(RHCustomDatePicker);
