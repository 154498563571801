import React, { FC } from "react";
import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import memoize from "utils/memoize";

export const RHPersonIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox="0 0 12 13" {...props}>
      <path
        d="M6.00285 6.3807C4.52102 6.3807 3.3125 5.17218 3.3125 3.69035C3.3125 2.20852 4.52102 1 6.00285 1C7.48468 1 8.6932 2.20852 8.6932 3.69035C8.6932 5.17218 7.48468 6.3807 6.00285 6.3807ZM6.00285 1.32028C4.69611 1.32028 3.63278 2.38361 3.63278 3.69035C3.63278 4.99709 4.69611 6.06042 6.00285 6.06042C7.30959 6.06042 8.37292 4.99709 8.37292 3.69035C8.37292 2.38361 7.30959 1.32028 6.00285 1.32028Z"
        fill="black"
        stroke="black"
        stroke-width="0.35"
      />
      <path
        d="M11.1322 12.4528H0.878953C0.831979 12.4528 0.789275 12.4315 0.759382 12.4016C0.729489 12.3717 0.712408 12.3204 0.720949 12.2777C1.01561 9.57458 3.28746 7.5376 6.0077 7.5376C8.72794 7.5376 10.9998 9.57458 11.2944 12.2777C11.2987 12.3247 11.2859 12.3674 11.256 12.4016C11.2261 12.4357 11.1834 12.4528 11.1364 12.4528H11.1322ZM1.05831 12.1325H10.9485C10.5941 9.68134 8.50161 7.85361 6.00343 7.85361C3.50525 7.85361 1.41275 9.68134 1.05831 12.1325Z"
        fill="black"
        stroke="black"
        stroke-width="0.35"
      />
    </SvgIcon>
  );
};

export default memoize(RHPersonIcon);
